thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: -1px;
  z-index: 0;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.4);
  border-radius: 7px;
}

.table-responsive {
  max-height: 60vh;
  margin-bottom: 10px;
}

.adjustment-table {
  max-height: 74vh;
}

.insemination-table {
  max-height: 74vh;
}

.listiagroup-table {
  max-height: 74vh;
}

.stocklist-table {
  max-height: 56vh;
}

.usermanagement-table {
  max-height: 80vh;
}

.eartaglist-table {
  max-height: 68vh;
}

.motherlist-table {
  max-height: 73vh;
}

.younglist-table {
  max-height: 73vh;
}

.fatteninglist-table {
  max-height: 73vh;
}

.statistical-weight-table {
  max-height: 75vh;
}

.command-table {
  max-height: 60vh;
}
