//
// react select
//

.react-select {
    > div {
        border: $input-border-width solid $input-border-color;
        // HGy: We should remove next line to be able to display visually when control is disabled:
        // background-color: $input-bg !important;

        &:hover {
            border: $input-border-width solid $input-border-color;
        }
    }
}

.rbt-token {
    background-color: rgba($primary,0.2) !important;
    color: $primary !important;
}

.rbt-input-multi.focus {
    box-shadow: none !important;
    background-color: $input-focus-bg !important;
    border-color: $input-focus-border-color !important;
}

.react-select__control--is-focused {
    border-color: $input-focus-border-color !important;
    box-shadow: none !important;
}

.react-select__indicator-separator {
    display: none !important;
}

.react-select__dropdown-indicator {
    color: $gray-600 !important;
}

.react-select__menu {
    background-color: $dropdown-bg !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: $dropdown-border-radius !important;
    z-index: $zindex-dropdown !important;
}

.react-select__menu-list {
    border: $dropdown-border-width solid $dropdown-border-color !important;
    border-radius: $dropdown-border-radius !important
}

.react-select__option {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x !important;
    color: $dropdown-link-color !important;

    &:hover {
        color: $dropdown-link-hover-color !important;
        background-color: $dropdown-link-hover-bg !important;
    }
}

.react-select__option--is-focused,
.react-select__option--is-selected {
    color: $dropdown-link-hover-color !important;
    background-color: $dropdown-link-hover-bg !important;
}

.react-select__multi-value {
    background-color: rgba($primary,0.2) !important;
    color: $primary !important;

    .react-select__multi-value__label {
        color: $primary;
    }

    .react-select__multi-value__remove {
        &:hover {
            background-color: rgba($primary,0.2) !important;
            color: $primary !important;
        }
    }
}