.auto-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.autoContainer {
  position: absolute;
  border-width: 0px 1px 0px 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  background-color: white;
  border-radius: 5px;
  width: 187px;
  top: 42px;
  z-index: 100;
  max-height: 500px;
  overflow: auto;
  box-shadow: 1px 2px 5px -1px rgba(0, 0, 0, 0.4);
}

.pos-rel {
  position: relative;
}

.option {
  padding: 10px;
  cursor: pointer;
}

.option:hover {
  background-color: rgb(236, 236, 236);
}
