//
// slimscroll.scss
//

@import '~simplebar/dist/simplebar.min.css';

.simplebar-scrollbar {
    width: 4px !important;
    &:before {
        background: rgb(158, 165, 171);
    }
}