.gp-table thead tr th {
  z-index: 0;
  background: white;
  box-shadow: none;
  border-radius: 0px;
  border: 1px solid !important;
  box-shadow: 0px 3px 3px -1px rgb(0 0 0 / 40%);
}

.gp-table thead {
  top: 0px;
  position: sticky;
}

.gp-table {
  border-collapse: separate !important;
}

.gp-summary-table-div {
  max-height: 77vh;
}
