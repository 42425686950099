.modal-content-size {
  width: 850px !important;
}

.modal-content-small-size {
  width: 500px !important;
}

.modal-align {
  max-width: 100%;
  display: flex;
  justify-content: center;
}
