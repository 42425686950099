.available-days {
  background-color: #b5d8fd;
  color: white !important;
  border-radius: 0.3rem;
}

.available-days:hover {
  background-color: #83bdfb !important;
}

.disabled-days {
  background-color: rgba(237, 237, 237, 0.4) !important;
  border-radius: 0.3rem;
}

.react-datepicker__week-number {
  color: #2c8ef8 !important;
  font-weight: 100 !important;
  font-size: large !important;
}
